<template>
  <div>
    <div class="text-center border-0" v-if="!sucess">
      <b-overlay :show="overlay">
        <div
          id="my-strictly-unique-vue-upload-multiple-image"
          class="justify-content-center d-flex"
        >
          <vue-upload-multiple-image
            @upload-success="uploadImageSuccess"
            @before-remove="beforeRemove"
            @edit-image="editImage"
            @mark-is-primary="markPrimary"
            @limitExceeded="limitExceeded"
            idUpload="myIdUpload"
            editUpload="myIdEdit"
            :dragText="dragText"
            :primaryText="primaryText"
            :browseText="browseText"
            :markIsPrimaryText="markIsPrimaryText"
            :popupText="popupText"
            :dropText="dropText"
            :data-images="images"
            :maxImage="max"
            style="width: 100%"
            show-delete
          />
        </div>

        <div v-show="images.length > 0">
          <p v-show="imageUploadError" class="text-danger">
            {{ imageUploadError }}
          </p>
          <b-button @click="uploadImage()" variant="primary" class="upload-btn"
            >{{ $t("ListingProcess.UploadImages") }}</b-button
          >
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import VueUploadMultipleImage from "vue-upload-multiple-image";
import axios from "axios";
// import draggable from "vuedraggable";
export default {
  components: {
    VueUploadMultipleImage,
    // draggable
  },
  data() {
    return {
      images: [],
      dragText: this.$t("ListingProcess.SelectImagesHere"),
      primaryText: this.$t("ListingProcess.PrimaryImage"),
      browseText: this.$t("ListingProcess.LimitOfImageUpload"),
      markIsPrimaryText: this.$t("ListingProcess.MarkAsPrimaryImage"),
      popupText: this.$t("ListingProcess.MarkAsPrimaryImage"),
      dropText: this.$t("ListingProcess.dropImageForUpload"),
      imageUploadSucess: false,
      imageUploadError: null,
      max: 20,
      overlay: false,
    };
  },
  computed: {
    reachMax() {
      return this.images.length >= this.max;
    },
    sucess() {
      return (
        this.$store.getters.vehicleImageList.length > 0 &&
        this.$store.getters.vehicleImagesUploaded
      );
    },
  },
  methods: {
    uploadImageSuccess(formData, index, fileList) {
      this.images = fileList;

      this.getImgFun(fileList[fileList.length - 1].path, fileList.length - 1);

      this.$store.dispatch("vehiclePrimaryImage", fileList[0].path);
      // Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      this.images = fileList;
      this.$store.dispatch("vehiclePrimaryImage", fileList[0].path);

      // this.showMsgBox()
      if (confirm("Are you sure to remove image?")) {
        done();

        // this.showMsgBox()
        // if (this.showMsgBox("Are you sure to remove image?")) {
        //   done();
        // }
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
      this.images = fileList;
      this.$store.dispatch("vehiclePrimaryImage", fileList[0].path);
    },
    markPrimary(index, fileList) {
      console.log("mark is Primary", index, fileList);
      this.images = fileList;
      this.$store.dispatch("vehiclePrimaryImage", fileList[0].path);
    },
    limitExceeded(maxImages) {
      console.log("over limitd", maxImages);
      alert("you have " + maxImages + " images, over limitd " + this.max + " images");
    },
    showMsgBox() {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm("Are you sure removed image?")
        .then((value) => {
          this.boxOne = value;
        })
        .catch((err) => {
          console.log("error: ", err);
        });
    },

    reorder() {
      console.log("images list has been reorded");
      console.log(this.images);
    },
    uploadImage() {
      let imagesUploading = [];
      this.images.forEach((element) => {
        if (element.highlight === 1) {
          imagesUploading.push({
            TagId: 167,
            Value: element.path.split(",")[1],
          });
        } else {
          imagesUploading.push({
            TagId: 16,
            Value: element.path.split(",")[1],
          });
        }
      });
      this.uploadImages(imagesUploading);

      if (this.imageUploadError === null) {
        this.imageUploadSucess = true;
      }
    },
    async uploadImages(upload) {
      try {
        this.overlay = true;
        this.imageUploadError = null;
        // const response = await axios.post("Listing/UploadImages", upload);
        const response = await axios.post("Listing/SetListing", {
          ListingId: this.$store.getters.listingStockID,
          Tags: upload,
        });
        this.$store.dispatch("vehicleImageList", response.data);
        this.images = [];
        this.overlay = false;
        const el = document.querySelector('#upload-photos-holder')
        el.scrollIntoView()

      } catch (error) {
        this.imageUploadError = error.response.data;
        this.imageUploadSucess = false;
        this.overlay = false;
        console.log(error);
      }
      await this.$store.dispatch("vehicleImagesUploaded", this.imageUploadSucess);
    },
    //拿到原始文件的base64的格式
    getImgFun(originalImg, index) {
      let that = this;
      let base64Arr = originalImg.split(",");
      let imgtype = "";
      let base64String = "";
      if (base64Arr.length > 1) {
        //如果是图片base64，去掉头信息
        base64String = base64Arr[1];
        imgtype = base64Arr[0].substring(
          base64Arr[0].indexOf(":") + 1,
          base64Arr[0].indexOf(";")
        );
      }
      // 将base64解码
      let bytes = atob(base64String);
      //let bytes = base64;
      let bytesCode = new ArrayBuffer(bytes.length);
      // 转换为类型化数组
      let byteArray = new Uint8Array(bytesCode);
      // 将base64转换为ascii码
      for (let i = 0; i < bytes.length; i++) {
        byteArray[i] = bytes.charCodeAt(i);
      }
      // 生成Blob对象（文件对象）
      let bolbObj = new Blob([bytesCode], { type: imgtype });
      let reader = new FileReader(); // 这个方法读取文件的bolb对象
      //将图片转成base64格式
      reader.readAsDataURL(bolbObj);
      reader.onloadend = function () {
        let result = this.result;
        let img = new Image();
        img.src = result;
        console.log("********original image size********");
        console.log(result.length / 1024);
        img.onload = function () {
          that.images[index].path = that.compress(img, 0.5);
          // that.images[index].path = that.cropping(that.compress(img, 0.1), 0, 0, 1280, 720);
        };
      };
    },
    // 压缩图片
    compress(img, size) {
      // 创建canvas
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      // let initSize = img.src.length
      let width = img.width;
      let height = img.height;
      // let width = 1280
      // let height = 720
      canvas.width = width;
      canvas.height = height;
      // 铺底色
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, width, height);
      //进行最小压缩
      let ndata = canvas.toDataURL("image/jpeg", size);
      console.log("*******Compressed image size*******");
      console.log(ndata.length / 1024);
      return ndata;
    },
  },
  // beforeMount() {
  //   if (this.$store.getters.vehicleImageList.length > 0) {
  //     this.images = this.$store.getters.vehicleImageList;
  //   }
  // },
  // beforeDestroy() {
  //   this.$store.dispatch("vehicleImageList", this.images);
  // }
};
</script>

<style lang="scss">
#my-strictly-unique-vue-upload-multiple-image {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.image-container {
  /* width: 996px !important;
  height: 600px !important; */
  margin: auto;
  width: 600px !important;
  height: 350px !important;
  border: 1px dashed #66a6db !important;
  border-radius: 10px;
  background-color: #fff;
  @media (max-width: 767px) {
    width: 400px !important;
  }
  @media (max-width: 575px) {
    width: 300px !important;
    height: 300px !important;
  }
  &.image-list {
    width: 225px !important;
    height: 195px !important;
    .show-image {
      width: 100%;
      height: 100%;
      padding: 15px;
      &:not(img) {
        transform: translate(-50%, -45%);
        height: 160px;
      }
    }
    img {
      object-fit: cover;
      max-height: 100%;
      max-width: 100%;
      width: 100%;
      padding: 0 !important;
    }
    .image-overlay {
      height: 145px !important;
    }
    .image-edit {
      margin-bottom: 0;
    }
  }
}

.image-primary {
  border-radius: 4px;
  margin-right: 5px;
  background: #4bb543 !important;
  height: 24px;
  color: white;
  font-size: 12px;
  min-width: 80px;
  align-items: center !important;
  justify-content: center;
  font-family: "Inter", sans-serif;
  .image-icon-primary {
    width: 15px;
    height: 15px;
    margin-right: 4px;
    circle {
      fill: white !important;
    }
    path {
      fill: #4bb543 !important;
    }
  }
  .image-icon-info {
    width: 18px;
    height: 18px;
  }
}

.image-list-container {
  column-gap: 16px;
  row-gap: 16px;
  max-width: 100% !important;
  min-height: auto !important;
  @media (max-width: 991px) {
    column-gap: 16px;
  }
  @media (max-width: 575px) {
    column-gap: 8px;
    row-gap: 8px;
  }
  .image-list-item {
    border: none !important;
    margin-right: 0;
    margin-bottom: 0 !important;
    width: calc((100% / 5) - 26px) !important;
    height: 173px !important;
    @media (max-width: 991px) {
      width: calc((100% / 4) - 12px) !important;
    }
    @media (max-width: 767px) {
      height: 130px !important;
    }
    @media (max-width: 575px) {
      width: calc((100% / 4) - 6px) !important;
      height: 90px !important;
    }
    @media (max-width: 480px) {
      height: 65px !important;
    }

    .centered {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        max-width: 100% !important;
        max-height: 100% !important;
      }
    }
    &.display-flex {
      border: 1px dashed #4b4b4b !important;
    }
  }
}

.add-image-svg {
  fill: #006ac3 !important;
}

/* .preview-image {
  height: 550px !important;
}*/

/* .show-image .show-img {
  max-height: 500px !important;
  max-width: 900px !important;
} */

.show-img {
  border-radius: 3px;
}

.image-list-container {
  max-width: auto !important;
  max-height: auto !important;
}

.drag-text {
  color: #006ac3 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: "Inter", sans-serif !important;
  margin-bottom: 0;
  padding-top: 16px !important;
}
.browse-text {
  color: #4b4b4b !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: "Inter", sans-serif !important;
}
.image-icon-drag {
  fill: #006ac3 !important;
  height: 64px !important;
  width: 64px !important;
}
.upload-btn {
  width: 340px;
  border-radius: 40px;
  height: 56px;
  margin: 24px auto;
  font-size: 20px;
  font-weight: 600;
  font-family: "Inter" !important;
  @media (max-width: 575px) {
    width: 100%;
  }
}
</style>
